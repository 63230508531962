var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { lineBreakToParagraphAndHtmltoBrTag } from '../utils/browserUtils';
var styles = {
    qa: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    text-align: left;\n    margin: 2.5em 0;\n    font-size: 20px;\n    align-items: flex-start;\n    color: inherit !important;\n    @media (max-width: 600px) {\n      font-size: 16px;\n    }\n  "], ["\n    text-align: left;\n    margin: 2.5em 0;\n    font-size: 20px;\n    align-items: flex-start;\n    color: inherit !important;\n    @media (max-width: 600px) {\n      font-size: 16px;\n    }\n  "]))),
    question: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 1em 0;\n    display: flex;\n    font-weight: bold;\n  "], ["\n    margin: 1em 0;\n    display: flex;\n    font-weight: bold;\n  "]))),
    answer: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 1em 0;\n    display: flex;\n  "], ["\n    margin: 1em 0;\n    display: flex;\n  "]))),
    icon: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 64px;\n    margin-right: 0.3em;\n    max-width: none;\n    font-weight: bold;\n    @media (max-width: 600px) {\n      font-size: 48px;\n    }\n  "], ["\n    font-size: 64px;\n    margin-right: 0.3em;\n    max-width: none;\n    font-weight: bold;\n    @media (max-width: 600px) {\n      font-size: 48px;\n    }\n  "]))),
    text: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-top: 1.7em;\n  "], ["\n    margin-top: 1.7em;\n  "]))),
    accordion: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin: 0;\n    background-color: inherit !important;\n    color: inherit !important;\n    text-align: left;\n    box-shadow: none;\n    &:before {\n      display: none;\n    }\n  "], ["\n    margin: 0;\n    background-color: inherit !important;\n    color: inherit !important;\n    text-align: left;\n    box-shadow: none;\n    &:before {\n      display: none;\n    }\n  "]))),
    accordionSummary: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    padding: 0;\n    margin: 0;\n    & .question {\n      margin: 0;\n    }\n  "], ["\n    padding: 0;\n    margin: 0;\n    & .question {\n      margin: 0;\n    }\n  "]))),
    accordionDetails: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    padding: 0;\n    display: flex;\n    & .answer {\n      margin: 0;\n    }\n  "], ["\n    padding: 0;\n    display: flex;\n    & .answer {\n      margin: 0;\n    }\n  "]))),
};
var QandA = function (_a) {
    var color = _a.color, block = _a.block;
    var isAccordion = block.qaList.length > 0 && block.qaList[0].isAccordion;
    return (_jsx(_Fragment, { children: block.qaList.map(function (qa) {
            if (!qa.question && !qa.answer) {
                return null;
            }
            var QuestionContent = (_jsxs("p", __assign({ css: styles.question, className: 'question' }, { children: [_jsx("span", __assign({ css: styles.icon, className: 'icon_question' }, { children: "Q" })), _jsx("span", __assign({ css: styles.text, className: 'question_text' }, { children: lineBreakToParagraphAndHtmltoBrTag(qa.question) }))] })));
            var AnswerContent = (_jsxs("p", __assign({ css: styles.answer, className: 'answer' }, { children: [_jsx("span", __assign({ css: [
                            css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n                  color: ", ";\n                "], ["\n                  color: ", ";\n                "])), color),
                            styles.icon,
                        ], className: 'icon_answer' }, { children: "A" })), _jsx("span", __assign({ css: styles.text, className: 'answer_text' }, { children: lineBreakToParagraphAndHtmltoBrTag(qa.answer) }))] })));
            // アコーディオン表示の場合
            if (isAccordion) {
                return (_jsxs(Accordion, __assign({ css: styles.accordion, defaultExpanded: false, className: 'qa_accordion' }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}), css: [
                                styles.accordionSummary,
                                css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n                    .MuiIconButton-root {\n                      color: inherit;\n                    }\n                  "], ["\n                    .MuiIconButton-root {\n                      color: inherit;\n                    }\n                  "]))),
                            ], className: 'qa_accordion_summary' }, { children: QuestionContent })), _jsx(AccordionDetails, __assign({ css: styles.accordionDetails, className: 'qa_accordion_details' }, { children: AnswerContent }))] }), qa.uid));
            }
            // 通常表示の場合
            return (_jsxs("div", __assign({ css: styles.qa, className: 'qa_normal' }, { children: [QuestionContent, AnswerContent] }), qa.uid));
        }) }));
};
export default QandA;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
