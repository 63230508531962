import chroma from 'chroma-js';
export var replacePlaceholder = function (form, template) {
    var _a;
    if (!template) {
        return template;
    }
    var placeholders = Array.from(template.matchAll(/\${([a-zA-Z0-9]+)}/g));
    var _loop_1 = function (placeholder) {
        var fieldName = placeholder[1];
        var fieldValue = form.fieldValues.find(function (f) { return f.fieldUid === fieldName; });
        var value = (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) || ((_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.values) === null || _a === void 0 ? void 0 : _a.join(','));
        // まだ対象フィールドが未設定の場合、undedinedを返してデフォルト値を表示させる。
        if (!value) {
            return { value: undefined };
        }
        template = template === null || template === void 0 ? void 0 : template.replace(placeholder[0], value);
    };
    for (var _i = 0, placeholders_1 = placeholders; _i < placeholders_1.length; _i++) {
        var placeholder = placeholders_1[_i];
        var state_1 = _loop_1(placeholder);
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return template;
};
export var darkThemeColor = function (enqueteSetting) {
    if (!enqueteSetting.themeColor) {
        return undefined;
    }
    return chroma(enqueteSetting.themeColor).darken(1).hex();
};
export var writeThemeColorStylesToHead = function (setting) {
    var themeColor = setting.themeColor, googleFont = setting.googleFont;
    // Googleフォントの読み込み
    if (googleFont) {
        // プリコネクトの追加
        var preconnectGoogle = document.createElement('link');
        preconnectGoogle.rel = 'preconnect';
        preconnectGoogle.href = 'https://fonts.googleapis.com';
        document.head.appendChild(preconnectGoogle);
        var preconnectGstatic = document.createElement('link');
        preconnectGstatic.rel = 'preconnect';
        preconnectGstatic.href = 'https://fonts.gstatic.com';
        preconnectGstatic.crossOrigin = 'anonymous';
        document.head.appendChild(preconnectGstatic);
        // フォントの読み込み
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = "https://fonts.googleapis.com/css2?family=".concat(googleFont.replace(/ /g, '+'), ":wght@400;700&display=swap");
        // パフォーマンス属性の追加
        link.setAttribute('media', 'print');
        link.setAttribute('onload', "this.media='all'");
        document.head.appendChild(link);
        // フォールバックの追加（JavaScript無効時用）
        var noscript = document.createElement('noscript');
        var fallbackLink = document.createElement('link');
        fallbackLink.rel = 'stylesheet';
        fallbackLink.href = link.href;
        noscript.appendChild(fallbackLink);
        document.head.appendChild(noscript);
    }
    var style = document.createElement('style');
    var styleContent = '';
    // フォントの適用
    if (googleFont) {
        styleContent += "\n      .global-main,\n      .navigationMenu_block,\n      .footerConversionButton_block {\n        font-family: '".concat(googleFont, "', sans-serif;\n      }\n    ");
    }
    else {
        styleContent += "\n      .global-main,\n      .navigationMenu_block,\n      .footerConversionButton_block {\n        font-family: inherit;\n      }\n    ";
    }
    // テーマカラーの適用
    if (themeColor) {
        styleContent += "\n      button {\n        background: ".concat(themeColor, ";\n      }\n\n      .site .site-title {\n        color:").concat(themeColor, ";\n      }\n\n      .site .name_section__input, .site .input-text input[type='text'], .site textarea{\n        border-color:").concat(themeColor, ";\n      }\n\n      .site .link {\n        background-color: ").concat(themeColor, ";\n        border-color:").concat(themeColor, ";\n      }\n\n      .site .theme-color {\n        color:").concat(themeColor, ";\n      }\n\n      .site .theme-background-color {\n        background-color: ").concat(themeColor, ";\n      }\n    ");
    }
    style.textContent = styleContent;
    document.head.appendChild(style);
};
