var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import useTrackClick from '../api/use-track-click';
import { replaceUrl } from '../utils/url';
import Img from './Img';
var BannerLinks = function (_a) {
    var block = _a.block;
    var bannerLinks = block.bannerLinks;
    var siteUid = window.location.pathname.substring(1);
    var domain = window.location.hostname;
    var path = window.location.pathname;
    var trackClick = useTrackClick().trackClick;
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var bannerWidth = '100%';
    if (bannerLinks.length === 1) {
        bannerWidth = '100%';
    }
    else if (bannerLinks.length === 2) {
        bannerWidth = '48%';
    }
    else if (bannerLinks.length === 3) {
        bannerWidth = '32%';
    }
    else {
        bannerWidth = '100%';
    }
    var styles = {
        wrapper: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      @media (max-width: 1024px) {\n        width: 96%;\n        margin: 0 auto;\n      }\n      @media (max-width: 600px) {\n        flex-flow: column;\n      }\n    "], ["\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      @media (max-width: 1024px) {\n        width: 96%;\n        margin: 0 auto;\n      }\n      @media (max-width: 600px) {\n        flex-flow: column;\n      }\n    "]))),
        image: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      width: ", ";\n      text-align: center;\n      & img {\n        display: inline-block;\n      }\n      @media (max-width: 600px) {\n        width: 100%;\n        margin: 1em 0;\n      }\n    "], ["\n      width: ", ";\n      text-align: center;\n      & img {\n        display: inline-block;\n      }\n      @media (max-width: 600px) {\n        width: 100%;\n        margin: 1em 0;\n      }\n    "])), bannerWidth),
        imageLeft: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      text-align: left;\n    "], ["\n      text-align: left;\n    "]))),
        imageCenter: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      text-align: center;\n    "], ["\n      text-align: center;\n    "]))),
        imageRight: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      text-align: right;\n    "], ["\n      text-align: right;\n    "]))),
    };
    // 画像の配置スタイルをマッピング
    var imageAlignStyles = {
        left: styles.imageLeft,
        center: styles.imageCenter,
        right: styles.imageRight,
    };
    var handleClick = useCallback(function (event, url, trackingUrl) {
        event.preventDefault();
        trackClick(siteUid, trackingUrl || url, domain, path, inflowSource).finally(function () {
            if (window == window.parent) {
                window.location.href = url;
            }
            else {
                window.open(url, '_blank');
            }
        });
    }, []);
    return (_jsx("section", __assign({ className: 'my-20 lg:w-3/4 m-auto' }, { children: _jsx("div", __assign({ css: styles.wrapper }, { children: bannerLinks.map(function (bannerLink, index) {
                return bannerLink.url ? (_jsx("p", __assign({ css: [
                        styles.image,
                        imageAlignStyles[bannerLink.imageAlign || 'left'], // デフォルトは左寄せ
                    ] }, { children: _jsx("a", __assign({ target: '_blank', rel: 'noreferrer', href: replaceUrl(bannerLink.url, inflowSource), onClick: function (event) {
                            return handleClick(event, replaceUrl(bannerLink.url, inflowSource), bannerLink.url);
                        } }, { children: _jsx(Img, { src: bannerLink.imagePath, alt: '', loading: 'lazy' }) })) }), index)) : (bannerLink && (_jsx("p", __assign({ css: [
                        styles.image,
                        imageAlignStyles[bannerLink.imageAlign || 'left'], // デフォルトは左寄せ
                    ] }, { children: _jsx(Img, { src: bannerLink.imagePath || '/images/1280x920.png', alt: '', loading: 'lazy' }) }), index)));
            }) })) })));
};
export default BannerLinks;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
